import { DateTime } from "luxon";
import { Device } from "../device/device.model";

export enum PointType {
  None = 0,
  DI = 1, // Indication
  DO = 2,
  AI = 3, // Measurement
  AO = 4,
  ConversionTable = 5,
  TimeSchedule = 6,
  Alarm = 7,
  ControlPoint = 13,
  Calendar = 17,
}

export enum LockState {
  Auto = 0,
  Program = 1,
  Manual = 2,
}

export enum ModeType {
  Manual = "Manual",
  Auto = "Auto",
}
export type AILimitType = {
  value: number;
  lockState: number;
  name: string;
  index: number;
};

export type AIExtension = {
  limits?: AILimitType[];
};

export class PointValue {
  pointId?: number;
  timestamp: string;
  lastUpdate: string;
  value: number | null;
  lockState: LockState | null;
  alarmState: number | null;
  errorState: number | null;
  label?: string;
  extension: AIExtension;

  constructor(value: {
    pointId: number;
    timestamp: string;
    lastUpdate: string;
    value: number | null;
    lockState: number | null;
    alarmState: number | null;
    errorState: number | null;
    label?: string;
    extension: AIExtension;
  }) {
    this.pointId = value.pointId;
    this.timestamp = value.timestamp;
    this.lastUpdate = value.lastUpdate;
    this.value = value.value;
    this.lockState = value.lockState;
    this.alarmState = value.alarmState;
    this.errorState = value.errorState;
    this.label = value.label;
    this.extension = value.extension;
  }
}

export class Point {
  id: number;
  name: string;
  device: Device;
  buildingName: string | null;
  description: string;
  type: PointType;
  unit: string | null;
  value?: PointValue;
  stateTexts?: { label: string; value: number }[];
  icalWeek?: string;
  icalExceptions?: string;

  constructor(point: Point) {
    this.id = point.id;
    this.name = point.name;
    this.device = point.device;
    this.buildingName = point.buildingName || null;
    this.description = point.description;
    this.type = point.type;
    this.unit = point.unit;
    this.value = point.value;
    this.stateTexts = point.stateTexts;
    this.icalWeek = point.icalWeek;
    this.icalExceptions = point.icalExceptions;
  }
}

export class PointGroup {
  id: number;
  companyId: number;
  name: string;
  description: string;
  points: Point[];

  constructor(group: {
    id: number;
    companyId: number;
    name: string;
    description?: string | null;
    points?: Point[];
  }) {
    this.id = group.id;
    this.companyId = group.companyId;
    this.name = group.name;
    this.description = group.description || "";
    this.points = group.points?.map((p) => new Point(p)) || [];
  }
}

export class PointHistoryItem {
  timestamp: Date;
  value: number | null;
  unit: string | null;

  constructor(pointValue: PointValue, point: Point) {
    this.timestamp = DateTime.fromISO(pointValue.timestamp).toJSDate();
    this.value = pointValue.value;
    this.unit = point.unit;
  }
}

export function getModelName(type: PointType) {
  const typeMap: { [key: number]: string } = {
    [PointType.ConversionTable]: "conversion-tables",
    [PointType.Alarm]: "alarms",
    [PointType.AI]: "measurements",
  };

  if (!(type in typeMap)) {
    throw new Error("Invalid point type");
  }
  return typeMap[type];
}
